.list {
    --Neutral-Black: #0A2432;
    --primary-blue-6-brand-color: #0061AF;
    --Primary-Blue-0: #F4F9FD;
    --Neutral-White: #FFF;

    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 0 32px;
    flex-wrap: wrap;
    align-items: baseline;

    .link {
        --button-radius: 8px;
        padding: 4px 12px;

        &:hover {
            background-color: var(--Primary-Blue-0, #F4F9FD);

            .label {
                color: var(--primary-blue-6-brand-color, #0061AF);
            }

            .section svg {
                color: var(--primary-blue-6-brand-color, #0061AF);
            }
        }

        .section {
            margin-left: 8px;

            svg {
                color: var(--Neutral-Black, #0A2432);
            }
        }

        .label {
            font-weight: 500;
            text-decoration: none;
            white-space: nowrap;
            color: var(--Neutral-Black, #0A2432);
            font-size: 16px;
            line-height: 28px; /* 175% */
        }
    }
}

.dropdown {
    padding: 4px;
    border: none;
    border-radius: 8px;
    background: var(--Neutral-White, #FFF);
    box-shadow: 0 0 4px 0 rgba(7, 59, 102, 0.04), 0 1px 8px 0 rgba(7, 59, 102, 0.06);

    .item {
        padding: 8px 12px;
        &:hover {
            background: var(--Primary-Blue-0, #F4F9FD);
        }
    }

    .itemLabel {
        color: var(--Neutral-Black, #0A2432);
        font-size: 14px;
        line-height: 24px; /* 171.429% */
    }
}