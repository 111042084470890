.form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .description {
        margin: 0;
        font-size: 16px;
        line-height: 28px;
    }

    .inputs {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .buttons {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
    }

    @mixin smaller-than $mantine-breakpoint-md {
        .inputs {
            gap: 16px;
        }

        .buttons {
            flex-direction: column-reverse;
        }
    }
}