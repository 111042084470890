.root {
    padding: 8px 16px;
    height: 44px;
    --button-hover: #F4F9FD !important;

    .label {
        display: flex;
        align-items: center;
        gap: 8px;

        &, .country {
            color: #0061AF;
            font-size: 16px;
            font-weight: 500;
            line-height: 28px; /* 175% */
        }

        .country {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #0061AF;
        }
    }
}