.form {
	display: flex;
	flex-direction: column;
	gap: 16px;
    width: 100%;

    .title{
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
        text-align: center;
        margin: 0 0 8px 0;
    }

	.forgotPasswordBlock {
		display: flex;
        justify-content: space-between;
		gap: 12px;
        margin: 8px 0;

        @mixin smaller-than $mantine-breakpoint-sm {
           flex-wrap: wrap;
        }

        .resetPasswordLink {
            padding: 0 !important;
            color: var(--blue-6);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-decoration: none;
        }
    }

	.moveToBlock {
        color: var(--blue-9);
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;

        text-align: center;

        .link{
            color: var(--blue-6);
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            text-decoration: none;
        }
	}
	.loginButtonInner {
	}

	.error {
		width: 100%;
		text-align: center;
		font-size: 14px;
		font-weight: 400;
		color: #ec1944;
	}
}
