.dropdown {
    border-radius: 8px;
    padding: 8px;

    .item {
        padding: 8px 12px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 175% */
    }

    .itemSection[data-position='left'] {
        margin-right: 8px;
    }
}

.exitItemLabel {
    color: #E60033;
}

.userItemLabel {
    max-width: 300px;
    overflow: hidden;

    p {
        min-width: 0;
        margin: 0;
        overflow: hidden;
        color: #0061AF;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 175% */
        letter-spacing: -0.16px;
    }

    .userEmail {
        color: #8493A8;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
    }
}