.form {
	display: flex;
	flex-direction: column;
	gap: 4px;
    width: 100%;
    max-width: 368px;

    .title{
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
        text-align: center;
        margin: 0 0 28px 0;
    }

	.subTitle {
		margin: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;

    }
	.changeEmail {
		margin: 0 0 28px 0;

		.changeEmailTitle {
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            color: var(--blue-9)

        }

		.changeEmailButton {
            margin-left: 6px;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
			cursor: pointer;
			user-select: none;
            color: var(--blue-6);
		}
	}

	.confirmTitle {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        color: var(--blue-9);
    }
    .pinInputRoot{
        justify-content: space-between;
    }

	.visibilityToggle {
		--ai-hover: transparent !important;
	}

	.forgotPasswordBlock {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.resetPasswordLink {
		align-self: flex-start;
		padding: 0 !important;
		color: #0e90fb;
		font-size: 14px;
		line-height: 16px;
	}

	.loginButtonInner {
	}

	.error {
		margin-top: 4px;
		padding-bottom: 4px;
		width: 100%;
		text-align: left;
		color: #c3042b;
		font-weight: 400;
		font-size: 12px;
		line-height: 1.16;
		height: 34px;
	}

	.resendButton {
		margin: 0 0 16px 0;
		display: inline;
		font-size: 14px;
		font-weight: 500;
		line-height: normal;
		color: #0e90fb;
		cursor: pointer;
		user-select: none;
	}

	.timerContainer {
		margin: 0 0 16px 0;

		.timerTitle {
			display: inline-block;
			text-decoration: unset;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            color: var(--blue-9);

        }
		.timerTime {
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
            margin-left: 8px;
            color: var(--blue-9);
		}
	}

    .moveToBlock {
        color: var(--blue-9);
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;

        margin-top: 12px;
        text-align: center;

        .link{
            color: var(--blue-6);
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            text-decoration: none;
        }
    }
}
