.popoverContainer {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 24px;
}

.dropdown {
	--popover-radius: 8px;

	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 24px;
	padding: 16px;
}

.saveButtonRoot {
	padding: 8px 16px;
	height: 40px;
	--button-radius: 12px;

	.saveButtonlabel {
		color: #0061AF;
		font-size: 14px;
		font-weight: 500;
		line-height: 24px; /* 171.429% */
	}
}

.selectRoot {

	.selectLabel {
		color: #0A2432;
		font-size: 14px;
		line-height: 24px; /* 171.429% */
		margin-bottom: 4px;
	}

	.selectWrapper {
		height: 40px;
	}

	.selectInput {
		height: 40px;
		border-radius: 8px;
	}

	.selectSection[data-position='left'] {
		color: #0061AF;
	}
}